import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';
@Injectable({
  providedIn: 'root'
})
export class AlertfyService {

  constructor(

  ) { }

  confirm(message: string, title: string, okCallback: () => any) {
    alertify.confirm(message, (e) => {
      if (e) {
        okCallback();
        return true;
      } else {
      }
    }).set({ title: title }).set({ labels: { ok: 'Ok', cancel: 'Cancel' } });
  }

  success(message: string) {
    alertify.success(message);
    alertify.set('notifier', 'position', 'top-center');
  }

  error(message: string) {
    alertify.error(message);
    alertify.set('notifier', 'position', 'top-center');
  }

  warning(message: string) {
    alertify.warning(message);
    alertify.set('notifier', 'position', 'top-center');
  }

  message(message: string) {
    alertify.message(message);
    alertify.set('notifier', 'position', 'top-center');
  }
}
