import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { loginModel } from './login';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { usersRoles } from '../cmsusers/cmsusersrole.model';
import { map } from "rxjs/operators";
import { DeviceDetectorService } from 'ngx-device-detector';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { JwtHelperService } from "@auth0/angular-jwt";


const authUrl = environment.apiURL;
const authUrl1 = environment.apiURL1;
const npmWURL = environment.npmWURL;
const acmdetails = environment.ACMDetailsURL;


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userRoles: usersRoles[] = [];
  private userRolesUpdated = new Subject<{ roles: usersRoles[] }>();
  private loggedIn = new BehaviorSubject<boolean>(true);
  public aggermntPage = false;
  //private loggedIn = new BehaviorSubject<boolean>(true);
  token: any;
  headers: any;
  ipAddress : any;
  deviceInfo : any = null;
  logInAct = {
    loginId   : '',
    Lat       : '',
    Lon       : '',
    Ipaddress : '',
    deviceType: '',
    deviceOs  : '',
    logType   : '',
    devicOsVersion : '',
    browserType : ''
  }

  lat : any;
  long : any;

  constructor(private http: HttpClient,
    private JwtHelperService:JwtHelperService,private router: Router,private deviceService: DeviceDetectorService) {

  }


  private htttpOptions() {
    this.token = localStorage.getItem("token");
    let httpOptions: any;
    if (this.token != null) {
      return httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          'x-access-token': this.token,
        })
      }
    }
  }


  private httpOptionImageSave() {
    this.token = localStorage.getItem("token");
    let httpOptionsForImage: any;
    if (this.token != null) {
      return httpOptionsForImage = {
        headers: new HttpHeaders({
          "Accept": "application/json",
          'x-access-token': this.token,
        })
      }
    }
  }


  ngOnInit(){
    this.logInAct = {
      loginId   : '',
      Lat       : '',
      Lon       : '',
      Ipaddress : '',
      deviceType: '',
      deviceOs  : '',
      logType   : '',
      devicOsVersion : '',
      browserType : ''
    }
  }


  ngAfterViewInit(){

  }

  isLoggedIn() {
    return this.loggedIn.asObservable();
  }


  setheaders(): void {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
    // if (this.accessToken){
    this.headers = this.headers.append('x-access-token', localStorage.getItem('Token'));
    // }
  }

  getheader(reqOpts) {
    if (!reqOpts) {
      reqOpts = {
        headers: this.headers
      };
    } else {
      reqOpts.headers = this.headers;
    }
    return reqOpts;
  }

 //user login
 Login(data){
  return this.http.post(authUrl+'auth/signin',data,)
}

 //403 token expire
 public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    return !this.JwtHelperService.isTokenExpired(token);
  }


  public getLocation() {

    if (navigator.geolocation) {
      console.log("Device full Info :", navigator.geolocation);
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }

    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res['ip'];
      this.logInAct.Ipaddress =this.ipAddress;
     // console.log('IP Address :',this.logInAct);
    });

    this.logInAct.loginId = localStorage.getItem('uId');
  }



  public showPosition(position) {
    localStorage.setItem('lat',position.coords.latitude);
    localStorage.setItem('long',position.coords.longitude);
   // console.log("Latitude: " + localStorage.getItem('lat')+ "<br>Longitude: " + localStorage.getItem('long'));
    if(position.coords.latitude != "" || position.coords.latitude != null){
        //console.log("Has Value...");
    }
  }


  epicFunction(){
    this.deviceInfo = this.deviceService.getDeviceInfo();

    console.log("Device Info :", this.deviceInfo);

    this.logInAct.deviceType =this.deviceInfo.deviceType;
    this.logInAct.browserType = this.deviceInfo.browser;
    this.logInAct.deviceOs = this.deviceInfo.os;
    this.logInAct.devicOsVersion = this.deviceInfo.os_version;
    this.logInAct.logType   = "1";
    this.logInAct.Lat = localStorage.getItem('lat');
    this.logInAct.Lon = localStorage.getItem('long');

    var postObj = {
      "action":"A",
      "user_Id": this.logInAct.loginId,
      "IP_address":this.logInAct.Ipaddress,
      "latitude":this.logInAct.Lat,
      "longitude":this.logInAct.Lon,
      "device_type":this.logInAct.deviceType,
      "device_OS":this.logInAct.deviceOs,
      "log_type":this.logInAct.logType,
      "deviceOsVersion":this.logInAct.devicOsVersion,
      "browser_type"  : this.logInAct.browserType
    }

    localStorage.setItem("deviceinfo", JSON.stringify(postObj));

    //console.log("Ip data :", postObj);
    this.http.post(authUrl + 'auth/loginactivites', postObj, this.htttpOptions()).subscribe((data: any) => {
        console.log("User Login Activities : ", data);
    });
  }

  get_dealerMonthList(data)
 {
  return this.http.post(authUrl + 'dealermonths/get',data,this.htttpOptions());
 }

 //search by subsections in labels
 updateDealerMonths(data){
  return this.http.post(authUrl + 'dealermonths/aud',data,this.htttpOptions())
}


  //search by subsections in labels
  SrchSubsec(data){
    return this.http.post(authUrl + 'labels/getsubsections',data,this.htttpOptions())
  }

  // LoginUser Activities

  getloginuserActivity(data){
    return this.http.post(authUrl + 'auth/getloginactivites', data, this.htttpOptions());
  }
  getfeloginuserActivity(data){
    return this.http.post(authUrl + 'auth/getfrontendloginactivites', data, this.htttpOptions())
  }
  postUserLoginActivity(postObj){
    return this.http.post(authUrl + 'auth/loginactivite', postObj, this.htttpOptions())
  }


  isLoggedInCheck() {
    let token = localStorage.getItem("token")
    if (!token) {
      this.loggedIn.next(false);
    }
    else {
      this.loggedIn.next(true);
    }
  }


  logOutUser() {
    this.router.navigate(['/login']);
    var deviceInfo = localStorage.getItem('deviceinfo');
    deviceInfo = JSON.parse(deviceInfo);
    //deviceInfo['log_type'] = 0;

    // console.log("Logout Device Info :", deviceInfo);
    this.http.post(authUrl + 'auth/loginactivites', deviceInfo, this.htttpOptions()).subscribe((data: any) => {
      console.log("User Logout Activities : ", data);
    });

    localStorage.removeItem('token')
    localStorage.removeItem('uId');
    localStorage.removeItem('roleId');
    localStorage.removeItem('userinfo');
    localStorage.clear();
    Cookie.deleteAll();

   // this.rout.navigate(['/dashboard'])

    
  }


//Save Login Info
saveLoginUserInfo(data){
  return this.http.post(authUrl + 'auth/loginactivites', data, this.htttpOptions())
}


//Json store data
storesInfoListjson(data) {
  // console.log(token);
  return this.http.post(authUrl + 'stores/storeinfojson', data, this.htttpOptions())
}

//Json brands data
 GetBrandsbyNameJson(Name) {
  return this.http.post(authUrl + 'brands/getbrandsdata', Name, this.htttpOptions())
}
brandsbyJson(data){
  return this.http.post(authUrl + 'brands/brandsjson', data, this.htttpOptions())
}
  // getting ViewLabels
GetViewLabels(obj){​​​​​​​​
return this.http.post(authUrl + 'labels/get', obj, this.htttpOptions())
}​​​​​​​​
//labels aud
SaveLabels(obj){​​​​​​​​
return this.http.post(authUrl + 'labels/aud', obj, this.htttpOptions())
}​​​​​​​​

get_Taskstatus_Data(data :any)
{
  return this.http.post(authUrl + 'taskstatustype/get',JSON.stringify(data),this.htttpOptions())
}

get_Taskstatus_SingleData(task)
{
  return this.http.post(authUrl + 'taskstatustype/get', task,this.htttpOptions());
}

save_taskstatus_Data(data)
{
  console.log(data);
  return this.http.post(authUrl + 'taskstatustype/aud',data,this.httpOptionImageSave());
}


update_taskstatus_Data(data)
{
  return this.http.post(authUrl + 'taskstatustype/aud',data,this.httpOptionImageSave());
}

  //Getting Dealer Content
  dealerContentList(dcId){
    var str = dcId;
    const data ={
     "dealer_id": str,
     "expression":""
    };
    return this.http.post(authUrl +'dealercontent/get',data,this.htttpOptions())
  }

  //Adding Dealer Content
  dealerContentAddUpdate(dt){
    return this.http.post(authUrl +'dealercontent/aud',dt,this.htttpOptions())
  }
// dealer content delete
deletingDealerSingleVariable(action: string, id: number){
  const DealerVariableData = { action: action, tw_id: id }
  return this.http.post(authUrl + 'detailsview/WeightedAvgVarianceCalcAction', DealerVariableData, this.htttpOptions())
  
}
  //gettig CmsModule...
  getCmsModules(moduleData) {
    return this.http.post(authUrl + 'cmsmodules/get', moduleData, this.htttpOptions())
  }

  //cmsSubModules-- adding
  addingSubModule(SubmoduleData) {
    return this.http.post(authUrl + 'cmsmodules/aued', SubmoduleData, this.htttpOptions())
  }

  //Send Mail
  sendAdreementMail(rid){
    var dt = {​​​​​​​​
    "dsc_id": rid
    }​​​​​​​​
    return this.http.post(authUrl + 'dealershipcontractform/send', dt, this.htttpOptions())
  }

  GetUsers(UserFlag) {
    return this.http.post(authUrl + 'auth/getadminusers', UserFlag, this.htttpOptions())
  }

  GetRoles(Id) {
    return this.http.post(authUrl + 'roles/get', Id, this.htttpOptions())
  }

  getEditSingleRole(data) {
    return this.http.post(authUrl + 'roles/get', data, this.htttpOptions())
 }

  GetTagsbyName(Name) {
    return this.http.post(authUrl + 'tags/gettagsbyname', Name, this.htttpOptions())
  }

  // GetAllBrands(obj) {
  //   return this.http.post(authUrl + 'brands/get', obj, this.htttpOptions())
  // }


  GetAllBrandsCopy() {
    var obj =  {
      "brandid": 0,
      "expression": "",
      "recordcount":1
  }
    return this.http.post(authUrl + 'brands/getallbrands', obj, this.htttpOptions())
  }


  GetBrandsbyName(Name) {
    return this.http.post(authUrl + 'brands/getbrandsbyname', Name, this.htttpOptions())
  }

  GetStates(countrycode) {
    return this.http.post(authUrl + 'states/getbycountry', countrycode, this.htttpOptions())
  }
  GetStatesJson(countrycode){
    return this.http.post(authUrl + 'states/statesjson', countrycode, this.htttpOptions())
  }

  GetStatesXml() {
    return this.http.get('/assets/States.xml',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'text/xml')
          .append('Access-Control-Allow-Methods', 'GET')
          .append('Access-Control-Allow-Origin', '*')
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
        responseType: 'text'
      })
  }

  GetCities(statecode) {
    return this.http.post(authUrl + 'cities/getbystate', statecode, this.htttpOptions())
  }

  SaveUsers(UserData) {
    return this.http.post(authUrl + 'auth/audadminuser', UserData, this.htttpOptions())
  }

  SaveUserImg(UserData) {
    return this.http.post(authUrl + 'auth/updateprofileimage', UserData, this.httpOptionImageSave())
  }

  ResetPassword(UserData) {
    return this.http.post(authUrl + 'auth/changepassword', UserData, this.htttpOptions())
  }

  addingMainSubModule(SubmoduleData) {

    return this.http.post(authUrl + 'cmsmodules/aued', SubmoduleData, this.htttpOptions())
  }

  editingMainSubModule(action: string, id: number) {
    const SubmoduleData = { action: action, id: id }
    return this.http.post(authUrl + 'cmsmodules/aued', SubmoduleData, this.htttpOptions())
  }

  deletingMainSubModule(action: string, id: number) {
    const SubmoduleData = { action: action, id: id }
    return this.http.post(authUrl + 'cmsmodules/aued', SubmoduleData, this.htttpOptions())
  }

  //getRoles

  //   getRole(id: number, exp) {
  //     const data = { id: id, expression: '' }
  //     return this.http.post(authUrl + 'roles/get', data, this.htttpOptions()).pipe(
  //       map((postData: any) => {
  //         if (postData.status == 200) {
  //           return {
  //             roles: postData.response.map((p) => {
  //               return {
  //                 RoleId: p.Role_Id,
  //                 RoleName: p.Role_Name,
  //                 RoleUniqId: p.Role_UniqId,
  //                 RoleStatus: p.Role_Status,
  //                 RoleTs: p.Role_Ts,
  //                 RoleAdmin: p.Role_Admin,
  //                 RoleFront: p.Role_Front,
  //                 RolePortal: p.Role_Portal
  //               };
  //             }),
  //           }
  //         };
  //       })
  //     )
  //       .subscribe((rolesDataTransfermedData) => {
  //         console.log(rolesDataTransfermedData);
  //
  //         this.userRoles = rolesDataTransfermedData.roles;
  //         this.userRolesUpdated.next({
  //           roles: [...this.userRoles],
  //         });
  //
  //         //console.log(this.postsUpdated);
  //       }, err => {
  //         console.log(err);
  //
  //       });
  //
  //   }
  getEditRole(data) {
    //const data = { id: id, expression: expression, Flag : Flag }
    return this.http.post(authUrl + 'roles/get', data, this.htttpOptions())
  }
  getUserRolesUpdateListener() {
    return this.userRolesUpdated.asObservable();
  }

  //addandUpdate roles..
  addingUpdateRoles(roleData) {

    return this.http.post(authUrl + 'roles/aud', roleData, this.htttpOptions())
  }

  deleteRole(action, roleId) {
    const roleDelete = { action: action, id: roleId }
    return this.http.post(authUrl + 'roles/aud', roleDelete, this.htttpOptions())
  }


  //Getting All Tags
  getUserTags(tagType: string) {
    const data = {
      "tagid": 0,
      "expression": "Tag_Type='" + tagType + "'"
    }
    return this.http.post(authUrl + 'tags/get', data, this.htttpOptions())
  }

  //Getting All Tags
  getAllTags(tagType: string, ln) {
    const data = {
      "tagid": 0,
      "expression": "Tag_Type='" + tagType + "'",
      "recordcount": ln
    }
    return this.http.post(authUrl + 'tags/get', data, this.htttpOptions())
  }

  //Getting All Tags Copy
  getAllTagsCopy() {
    const data = {
      "tagid": 0,
      "expression": "",
      "recordcount": 0
    }
    return this.http.post(authUrl + 'tags/get', data, this.htttpOptions())
  }



  //Post Tags Data / Update Tag / Delete Tags
  tagsPostData(tagObj: object) {
    return this.http.post(authUrl + 'tags/aud', tagObj, this.htttpOptions())
  }


  //Post dealers group data
  dealersGroupPostData(Obj: object) {
    const data = Obj;
    return this.http.post(authUrl + 'dealergroups/aud', data, this.httpOptionImageSave())
  }

  //Edit dealers group data
  // dealersGroupUpdateData(tagObj: object) {
  //   const data = tagObj;
  //   return this.http.post(authUrl + 'dealergroups/aud', data, this.htttpOptions())
  // }

  //Getting Dealers Group Data
  dealersGroupGettData(gdt) {

    return this.http.post(authUrl + 'dealergroups/get', gdt, this.htttpOptions())
  }

  //Delete Dealers Group
  deleteDealerGroupRecord(dgId) {
    const data = {
      "action": "D",
      "id": dgId
    };
    return this.http.post(authUrl + 'dealergroups/aud', data, this.htttpOptions())
  }


  //Save Signature
  saveSignature(obj) {
    return this.http.post(authUrl + 'dealershipcontractform/agreementsign', obj, this.htttpOptions())
  }

  //Save Signature
  sendContractFormPdf(obj) {
    return this.http.post(authUrl + 'dealershipcontractform/sendpdf', obj, this.htttpOptions())
  }
  
  GetAllBrands(obj) {
    return this.http.post(authUrl + 'brands/getallbrands', obj, this.htttpOptions())
}

  //Getting stores Info List
  storesInfoList(data) {
    // console.log(token);
    return this.http.post(authUrl + 'stores/get', data, this.htttpOptions())
  }
  storesInfoListJson(data){
    return this.http.post(authUrl + 'stores/storeinfojson', data, this.htttpOptions())
  }

   //Getting All stores Info List
   gettAllStoreInfoList() {
     var dt = {
      "si_id":0,
      "expression":"",
      "recordcount":0
      }
    return this.http.post(authUrl + 'stores/get', dt, this.htttpOptions())
  }

  //Add New Stores info
  addStoreInfo(data, reqOpts?: any) {
    // console.log(token);
    reqOpts = this.getheader(reqOpts);
    return this.http.post(authUrl + 'stores/aud', data, this.httpOptionImageSave())
  }

  //Getting Dealers Hours list
  getDealersHoursList(obj) {
    return this.http.post(authUrl + 'dealergroups/getdealerhours', obj, this.htttpOptions())
  }

  //Getting Dealers Hours list
  updateDealersHours(obj) {
    return this.http.post(authUrl + 'dealergroups/dealerhours', obj, this.htttpOptions())
  }

  //Getting Store holidays
  getStoreHolidays(obj) {
    return this.http.post(authUrl + 'storeholidays/get', obj, this.htttpOptions())
  }

  // Departments
  GetDepartment(obj) {
    return this.http.post(authUrl + 'department/get', obj, this.htttpOptions())
  }


  //Post user/constacts
  newContacts(obj) {
    return this.http.post(authUrl + 'dealercontacts/aud', obj, this.htttpOptions())
  }

  //Post user/constacts
  deleteContact(obj) {
    return this.http.post(authUrl + 'dealercontacts/aud', obj, this.htttpOptions())
  }

  SaveDepartment(Data) {
    return this.http.post(authUrl + 'department/aud', Data, this.htttpOptions())
  }

  //MDPPlans Grid
  getMdbPlans(MdbPlan) {
    const data = {
      id: MdbPlan.id,
      expression: MdbPlan.expression,
      recordcount: MdbPlan.recordcount
    }
    return this.http.post(authUrl + 'mdbplans/get', data, this.htttpOptions())
  }

  //Adding Subscription details
  addSubscriptionDetails(subObj) {
    const data = subObj;
    return this.http.post(authUrl + 'subscriptions/aud', data, this.htttpOptions())
  }

  //Actionplan Departments

  GetActplndprt(data)
  {
    return this.http.post(authUrl + 'actionplandeparment/get',data,this.htttpOptions());
  }

  SaveActplndprt(data)
  {
    return this.http.post(authUrl + 'actionplandeparment/aud',data,this.htttpOptions());
  }

  addMdbPlans(MdbPlan) {
    const data = {
      action: MdbPlan.action,
      mdb_id: MdbPlan.name,
      setupfee: MdbPlan.setupfee,
      monthlyfee: MdbPlan.monthlyfee
    }
    return this.http.post(authUrl + 'mdbplans/aud', data, this.htttpOptions())
  }


  updateMdbPlans(MdbPlan) {
    const data = {
      action: MdbPlan.action,
      id: MdbPlan.id,
      mdb_id: MdbPlan.name,
      setupfee: MdbPlan.setupfee,
      monthlyfee: MdbPlan.monthlyfee,
      status: MdbPlan.status
    }
    return this.http.post(authUrl + 'mdbplans/aud', data, this.htttpOptions())
  }
  deleteMdbPlans(MdbPlan) {
    const data = {
      action: MdbPlan.action,
      id: MdbPlan.id,

    }
    return this.http.post(authUrl + 'mdbplans/aud', data, this.htttpOptions())
  }



//storecontacts

  //reset pwd in store contacts
  resetPassword(data){
    return this.http.post(authUrl + 'storecontacts/adminresetpassword',data,this.htttpOptions())
  // return this.http.post('http://localhost:3000/api/storecontacts/adminresetpassword',data,this.htttpOptions())
  }

  GetStorecontactUsers(UserFlag) {
    console.log(localStorage.getItem("token"));
    return this.http.post(authUrl + 'storecontacts/get', UserFlag, this.htttpOptions())
  }

  SaveStorecontactUsers(UserData, reqOpts?: any){
    reqOpts = this.getheader(reqOpts);
     return this.http.post(authUrl + 'storecontacts/aud', UserData, this.httpOptionImageSave())
  }

  // Get roles for store contact users
  GetStorecontactroles(obj){
    return this.http.post(authUrl + 'roles/GetDealerUserRoles', obj,this.htttpOptions())
  }
 

  //Dealers by name
  GetStoresbyName(UserFlag) {
    return this.http.post(authUrl + 'stores/getstorebyname', UserFlag, this.htttpOptions())
  }

  //Dealers Groups by name
  GetDealersGroupbyName(txt) {
    return this.http.post(authUrl + 'dealergroups/getdealergroupbyname', txt, this.htttpOptions())
  }

  //Plan pricing by name
   GetPlanPricingbyName(txt) {
    return this.http.post(authUrl + 'mdbplans/getmdbplansbyname', txt, this.htttpOptions())
  }

  //Getting subscriptions details
  GetSubscriptionDetails(obj){
    return this.http.post(authUrl + 'subscriptions/get', obj, this.htttpOptions())
  }

  getPermissionRole(roleId, count) {
    const data = {
      P_CR_Id: roleId,
      expression: '',
      recordcount: count
    }
    return this.http.post(authUrl + 'cmspermissions/get', data, this.htttpOptions())
  }
  savePermissionByRole(permissionData) {
    const data = {
      p_mod_id: permissionData.p_mod_id,
      p_cr_id: permissionData.p_cr_id,
      p_mod_type: permissionData.p_mod_type,
      p_userid: permissionData.p_userid
    }
    return this.http.post(authUrl + 'cmspermissions/aud', data, this.htttpOptions())
  }

  getContractForm(obj) {
    console.log(this.token);
    return this.http.post(authUrl + 'dealershipcontractform/get', obj, this.htttpOptions())
   // return this.http.post('http://localhost:10.10.10.58:3000/api/dealershipcontractform/get', obj, this.htttpOptions())
  }

  SaveContractForm(FmData) {
    return this.http.post(authUrl + 'dealershipcontractform/aud', FmData, this.htttpOptions())
  }


  //Add Holidays- Dealer-hours
  SaveHolidays(Data) {
    return this.http.post(authUrl + 'storeholidays/aud', Data, this.htttpOptions())
  }

  GetHoliday(obj) {
    return this.http.post(authUrl + 'storeholidays/get', obj, this.htttpOptions())
  }


  /***************** Sai **********************/

  //action plan labels


   //getActionPlanlabels
   getActionPlanlabels(obj){
     return this.http.post<any>(authUrl+"actionplanlabels/get",obj,this.htttpOptions())
   }
   //edit or add
   SaveActionPlanLabels(obj){
     return this.http.post<any>(authUrl + "actionplanlabels/aud",obj,this.htttpOptions())
   }

  //get all records
  getData(){
    return this.http.post<any>(authUrl+"dealerdepartments/get",
      JSON.stringify({
      "d_id":0,
      "expression":"",
      "recordcount":1
     }),
     this.htttpOptions())
  }

     //fetch single record with id
    getSingleData(id){
      const data = {
        "d_id":id,
        "expression":"",
        "recordcount":1
      }
      return this.http.post<any>(authUrl+'dealerdepartments/get', data,
       this.htttpOptions());
    }



    //create record
    createData(obj){
      return this.http.post<any>(authUrl+"dealerdepartments/aud",obj,this.htttpOptions());
    }




    // //update the record
    updateData(objs){
      return this.http.post<any>(authUrl+"dealerdepartments/aud",objs,this.htttpOptions());
    }



    //delete the record
    deleteData(id) {
      const deleted = {
        "action":"D",
        "dd_id":id
      }
      return this.http.post<any>(authUrl+"dealerdepartments/aud",deleted,this.htttpOptions());
    }


//getting inventory
//  getInventory(obj){
//    return this.http.post(authUrl + 'cdkdmsdealerdata/InventoryMW', obj, this.htttpOptions())
//  }

 //getting Inventory
 gettingInventory(obj){
  return this.http.post(authUrl1+'NPBulkData/GetNPInventoryData',obj,this.htttpOptions())
}


//getting brands from xml
GetBrandsXml(){
  return this.http.get('/assets/Brands.xml',
  {
    headers: new HttpHeaders()
    .set('Content-Type', 'text/xml')
    .append('Access-Control-Allow-Methods', 'GET')
    .append('Access-Control-Allow-Origin', '*')
    .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
    responseType: 'text'

  })

}



getSingleSectionData(data)
{
  return this.http.post(authUrl + 'sectionnames/get' ,data,this.htttpOptions());
}

update_Section_Data(data)
{
  console.log(data);
  return this.http.post(authUrl + 'sectionnames/aud',data,this.htttpOptions());
}




getViewSectionData(data)
{
  console.log(data);
  return this.http.post(authUrl + 'sectionnames/get',data,this.htttpOptions());
}

save_Section_Data(data)
{
  console.log(data);
  return this.http.post(authUrl + '/sectionnames/aud',data,this.htttpOptions());
}

getDealersData(data)
{
  console.log(data);
  return this.http.post(authUrl + 'detailsview/GetWeightedAvg',data,this.htttpOptions());
}// return this.http.post('http://npapi.azaz.com/sq/api/detailsview/GetWeightedAvg' ,data); }

  saveDealersData(data)
  {
    console.log(data);
    return this.http.post(authUrl + 'detailsview/WeightedAvgVarianceCalcAction',data,this.htttpOptions());
  }

  save_SectionTitle_Data(data)
  {
    console.log(data);
    return this.http.post(authUrl + 'sectionnames/audtitle',data,this.htttpOptions());
  }

  get_SectionTitle_Data(data)
  {
    console.log(data);
    return this.http.post(authUrl + 'sectionnames/gettitle',data,this.htttpOptions());
  }


 //Staffing Labels

 getstafflabels(data){
  return this.http.post(authUrl + 'staffinglabels/get', data, this.htttpOptions());
}


//AUD staffing Labels

savestaffglbls(data){
return this.http.post(authUrl + 'staffinglabels/aud', data, this.htttpOptions());
}
//subsection search
Subsecsrch(data){
return this.http.post(authUrl + 'staffinglabels/getstaffingsubsection', data, this.htttpOptions());
}




// staffing subsectionNames
getstafsubsctname(data){
return this.http.post(authUrl + 'staffinglabels/getstaffingjunctions', data, this.htttpOptions());
}



//AU staffing subsectionNames
savestaffgsubct(data){
return this.http.post(authUrl + 'staffinglabels/audstaffingjunctions', data, this.htttpOptions());
}


//forgot-pwd-link
GetEmail(data){
  return this.http.post(authUrl+'auth/resetpasswordemaillink',data,)
}

//reset or change pwd page
GetPwdLink(data){
return this.http.post(authUrl+"auth/resetpassword",data)
}

// keysequence update in viewlabels
ViewLabelsSavekeyseq(data){
  return this.http.post(authUrl + 'labels/labelssequpdate', data, this.htttpOptions())
}

// staffing labels keysequence update
Staffingkeysequpdte(data){
  return this.http.post(authUrl + 'staffinglabels/staffinglabelsseq', data, this.htttpOptions());
}

//dealer-days

//get-dealer-days
dealerDaysGet(data){
   return this.http.post(authUrl+"dealerdays/get",data,this.htttpOptions())
 
}

//add-Edit
dealerDaysEdit(data){
  return this.http.post(authUrl+"dealerdays/aud",data,this.htttpOptions())

}

//task screen

  //Gettask data
  getTaskData(data){
    return this.http.post(authUrl+'tasq/get',data,this.htttpOptions())
  
    }

    //add & Edit & delete
   taskDataUpdate(data){
    return this.http.post(authUrl+"tasq/aud",data,this.htttpOptions())
  
   }
  
   taskDataCompleted(data){
   return this.http.post(authUrl+"tasq/tasqupdate",data,this.htttpOptions())
  

 }
  
 //dealer-users-Pwd change
  pwdChangeDealarusers(data){
    return this.http.post(authUrl+"storecontacts/resetpassword",data,this.htttpOptions())
  }

  SaveBrands(data){
    return this.http.post(authUrl + "brands/aud",data,this.httpOptionImageSave())
  }

 //migration page
  getMonthyear(){
    return this.http.post(authUrl + "dealermonths/GetMonthlyDealers",'', this.htttpOptions())
  }
  getNCMmonthyear(){
    return this.http.post(npmWURL+ "stores/getStoreMonths",'', this.htttpOptions())
  }

  //subsection dropdownlist based on sectionname
   getsubsectionlist(obj){
     return this.http.post(npmWURL+ "stores/SectionList", obj, this.htttpOptions())
    // return this.http.post("http://10.10.10.84:3501/api/stores/SectionList", obj, this.htttpOptions())
   }

//comparision grid binding
 getcomparisions(obj){
  return this.http.post(npmWURL+'comparisions/ComparisionsStatus',obj,this.htttpOptions())
 }
 // actuals grid binding
 getactuals(obj){
  return this.http.post(npmWURL+'actuals/ActualsStatus',obj,this.htttpOptions())
 }
 // dashboard grid binding
 getdashboard(obj){
   return this.http.post(npmWURL+'dashboard/DashboardStatus',obj,this.htttpOptions())
  // return this.http.post('http://10.10.10.84:3501/api/dashboard/DashboardStatus',obj,this.htttpOptions())
 }
 // actualPlans grid binding
 getactionplans(obj){
  return this.http.post( npmWURL + 'actionplans/ActionPlansStatus',obj,this.htttpOptions())
 }
  
 getmigratedstoreslist(obj){
   return this.http.post( npmWURL + 'stores/GetMigrationStoresList',obj,this.htttpOptions())
 }

getmigratecall(url,obj){
  return this.http.post(npmWURL+url,obj,this.htttpOptions())
}

// ACM Details
getACMDetails(obj){
  return this.http.post( acmdetails + 'detailsview/DetailsMigration',obj)
}
//users - history 
getlogdata(data){
  return this.http.post( authUrl+ 'storecontacts/GetStorecontactsLog',data,this.htttpOptions())
}

/// APP DB dashboard
// getInternalPerformance
getInternalPerformance(obj){
 return this.http.post(npmWURL + 'dashboard/getInternalPerformance',obj,this.htttpOptions())
}
// getInternalPerformance_details
getInternalPerformance_details(obj){
  return this.http.post(npmWURL + 'dashboard/getInternalPerformance_details',obj,this.htttpOptions())
}
//getInternalSectiongrades
getInternalSectiongrades(obj){
  return this.http.post(npmWURL + 'dashboard/getInternalSectiongrades',obj,this.htttpOptions())
}

//getInternalSectiondetails
getInternalSectiondetails(obj){
  return this.http.post(npmWURL + 'dashboard/getInternalSectiondetails',obj,this.htttpOptions())
}
//getInternalSections
getInternalSections(obj){
  return this.http.post(npmWURL + 'dashboard/getInternalSections',obj,this.htttpOptions())
}
//getInternalVariance
getInternalVariance(obj){
  return this.http.post(npmWURL + 'dashboard/getInternalVariance',obj,this.htttpOptions())
}
// getInternalTrending
getInternalTrending(obj){
  return this.http.post(npmWURL + 'dashboard/getInternalTrending',obj,this.htttpOptions())
}

//getMultipliers
getMultiplers(obj){
  return this.http.post(npmWURL + 'dashboard/getInternalMultipliers',obj,this.htttpOptions())
}
//getInternalPotentialActionPlans
getInternalPotentialActionPlans(obj){
  return this.http.post(npmWURL + 'actionplans/getInternalPotentialActionPlans',obj,this.htttpOptions())
}
//getInternalGainCalculator
getInternalGainCalculator(obj){
  // return this.http.post('http://10.10.10.84:3501/api/actionplans/getInternalGainCalculation',obj,this.htttpOptions())
  return this.http.post(npmWURL + 'actionplans/getInternalGainCalculation',obj,this.htttpOptions())
}

getMigrationStatus(obj){
 return this.http.post(npmWURL + 'stores/getMigrationStatus',obj,this.htttpOptions())
}
// Reports Tab
ActionplanReports(obj){
  // return this.http.post('http://10.10.10.84:3501/api/actionplans/ActionplanReports',obj,this.htttpOptions())
  return this.http.post(npmWURL + '/actionplans/ActionplanReports',obj,this.htttpOptions())
}

// getStoreStatus(obj){
//   return this.http.post('http://10.10.10.84:3501/api/stores/GetStoreStatus',obj,this.htttpOptions())
// }

// ACM Migration
ACMmigration(endpoint,obj){
  return this.http.post(`${npmWURL}${endpoint}`,obj,this.htttpOptions()).pipe(map((res:any)=>{
    return res;
  }))
}

// CDK-Tracking
gettrackingdata(obj){
  return this.http.post(authUrl1 + 'NPBulkData/NPDMSTracking',obj,this.htttpOptions())
}
//DMS DATA
getdmssourcetype(obj){
  return this.http.post(authUrl1 + 'NPGLAData/GetDMSSourceType',obj,this.htttpOptions())
} 
getdmsgldata(obj){
  return this.http.post(authUrl1 + 'NPGLAData/GetGLData',obj,this.htttpOptions())
}

// Migration report

getMigrationRep(obj){
  return this.http.get('stores/GetNCMmigrationReport',obj)
}
}
