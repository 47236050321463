import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardSidebarComponent } from './dashboard-sidebar/dashboard-sidebar.component';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { AuthenticationGuard } from './auth/authentication.guard';
import { QuillModule } from 'ngx-quill';
// import { NgMultiSelectorDropDownModule} from "ng-multiselect-dropdown";


@NgModule({
  declarations: [				
    AppComponent,
    HeaderComponent,
    DashboardSidebarComponent
      
   ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    QuillModule.forRoot(),
    // NgMultiSelectorDropDownModule.forRoot()
  ],
  
  providers: [AuthenticationGuard, { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService],
  bootstrap: [AppComponent]
})
export class AppModule { }
