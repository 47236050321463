import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Router, RouterEvent } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DeviceDetectorService } from 'ngx-device-detector';

import * as $ from 'jquery' 
import { element } from 'protractor';

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.css']
})

export class DashboardSidebarComponent implements OnInit {
  isLoggedIn  : Observable<boolean>;
  isLoggIn    : boolean = false;
  aggrmnt     : boolean;
  menuModules : any = [];
  i = 0;
  activePath : any = '';
  monthyear : any = [];
  mnyr : any='';
  listcount : any="";

  logInAct = {
    loginId   : '',
    Lat       : '',
    Lon       : '',
    Ipaddress : '',
    deviceType: '',
    deviceOs  : '',
    logType   : '',    
    devicOsVersion : '',
  }

  ipAddress : any;
  deviceInfo : any;

  //@ViewChild('subMenu') subMenu: ElementRef;

  constructor(private http: HttpClient,private router: Router,private auth: AuthService, private cdr: ChangeDetectorRef,private deviceService: DeviceDetectorService) { 

    this.router.events.subscribe((event: RouterEvent) => {    
      if(event.url != "" && event.url != undefined){
        //console.log("Page Url :", event.url);
        if(event.url === '/software-agreement' || event.url === '/passwordreset'){
          this.aggrmnt = true;
        }else{
          this.aggrmnt = false;
        }
        this.activePath = event.url
      }
    })
   
  }

  ngOnInit(): void {

    //console.log("Check NgOnInti...!");
    this.auth.isLoggedInCheck();
    this.isLoggedIn = this.auth.isLoggedIn();
    this.aggrmnt = this.auth.aggermntPage;
    this.isLoggedIn.subscribe((logginData) => {
      this.isLoggIn = logginData;
      //console.table(this.isLoggIn)
      this.cdr.detectChanges();
      this.getMenuModules();
       this.storeStatus();
      this.getNCMmonthyear();
       this.getmigratedstoreslist();
    })    

    this.logInAct = {
      loginId   : '',
      Lat       : '',
      Lon       : '',
      Ipaddress : '',
      deviceType: '',
      deviceOs  : '',
      logType   : '',    
      devicOsVersion : '',
    }
  }

  logOut() {
    this.isLoggIn = false;
    this.auth.logOutUser();
  }


  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("indiv").style.display = "block";
    //document.getElementById("main").style.marginLeft = "250px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }

  closeNav() {
    document.getElementById("indiv").style.display = "none";
    document.getElementById("mySidenav").style.width = "0";
    //document.getElementById("main").style.marginLeft = "0";
    document.body.style.backgroundColor = "white";
  }

  //Open Submenu
  openDiv(dv) {
    //console.log(dv);
    var x = document.getElementById(dv);
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  step = 0;
  menuOpenedId: string = '';
  subMenuItems: boolean;
  expand(number) {

    if (this.menuOpenedId == number) {
      this.subMenuItems = !this.subMenuItems;
      //console.log(this.subMenu);
    }else {
      this.subMenuItems = true;
    }
    
    this.menuOpenedId = number;
  }

  getMenuModules() {

    const modulesCms = {
      id: 0,
      expression: "",
      roleid: 0,
      type: "A",
      flag: "",
      recordcount: this.i
    }
    let roleid = localStorage.getItem('roleId')
    this.auth.getPermissionRole(roleid, 0).subscribe((resCmsModule: any) => {
      //console.log(resCmsModule);
      if (resCmsModule.status == 200) {

        this.menuModules = resCmsModule.response.recordset;
        //this.cmsModulesMap = resCmsModule.response;
        //console.log(this.menuModules, 'vv');
      }

    }, err => {

    })
  }


  getNCMmonthyear() {
    this.auth.getNCMmonthyear().subscribe((data: any) => {
      console.log('NCMmonths', data)
      let mnhty = data.response;
      let monthyear1 = mnhty[0];
      console.log(monthyear1,'arraylast');
      this.mnyr = monthyear1.value;
      this.monthyear = monthyear1.name;
     this.getmigratedstoreslist()
     this.storeStatus();
    });
  }
  getmigratedstoreslist() {
    var now = new Date();
    var oneMonthAgo = new Date(new Date().getFullYear(),new Date().getMonth() - 1,new Date().getDate());
    now.setMonth(now.getMonth()-1);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var today = ""+ oneMonthAgo.getFullYear() + month
    const obj = {
      "month_year": today 
    }
    console.log(this.mnyr,'sjhjf')
    this.auth.getmigratedstoreslist(obj).subscribe((data: any) => {
      let dealersList = data.response;
      let list = dealersList.filter((element)=>{
        if(element.migrated_status !="Y"){
          this.listcount = dealersList.length;
        }
        else{
          this.listcount = 0;
        }
      })
     })
  }

  storeStatus() {
    const obj = {
      "match": {
        "month_year": this.mnyr
      },
      "query": {
        "store_info.store_name": 1,
        "store_id": 1,
        "month_year": 1
      }
    }
    this.auth.getMigrationStatus(obj).subscribe((data:any)=>{
      console.log(data);
      let dealersList = data.response.recordset;
      console.log(dealersList,'fgjfdhsg')
      let list = dealersList.filter((element)=>{
        if(element.migrated_status !="Y"){
          this.listcount = dealersList.length;
          console.log(this.listcount,'fg')
        }
        else{
          this.listcount = 0;
        }
      })
    })
  }
}

