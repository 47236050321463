import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {
  public userprofile = environment.userprofile;
  isLoggedIn: Observable<boolean>;
  isLoggIn: boolean = false;
  loginName :  any;
  userLogo  : any;
  aggrmnt   : boolean = false;
  
  constructor(private auth: AuthService, private cdr: ChangeDetectorRef) {
    this.loginName = localStorage.getItem('ueraName');
    this.userLogo  = localStorage.getItem('profilePic');
    console.log('Log name :', this.loginName);
  }

  

  ngOnInit(): void {
    console.log("Check NgOnInti...!");
    this.auth.isLoggedInCheck();
    this.isLoggedIn = this.auth.isLoggedIn();
    this.isLoggedIn.subscribe((logginData) => {
      this.isLoggIn = logginData;
      console.table(this.isLoggIn)
      this.cdr.detectChanges();
    });
  }

  logOut() {
    this.isLoggIn = false;
    this.auth.logOutUser();
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("indiv").style.display = "block";
    //document.getElementById("main").style.marginLeft = "250px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }

  closeNav() {
    document.getElementById("indiv").style.display = "none";
    document.getElementById("mySidenav").style.width = "0";
    //document.getElementById("main").style.marginLeft = "0";
    document.body.style.backgroundColor = "white";
  }

  //Open Submenu
  openDiv(dv) {
    var x = document.getElementById(dv);
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }


}




