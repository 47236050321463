

import {NgModule, Component, OnDestroy } from '@angular/core';

@Component({
	selector: 'loading',
	templateUrl:'./loading.component.html',
	styleUrls: ['loading.scss']
})

export class LoadingComponent {

}
