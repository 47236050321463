import { Component, HostListener, OnInit,ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { loginModel } from '../auth/login';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmedValidator } from '../confirmvalidator';
import { AlertfyService } from '../alertfy.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {

  username: any;
  password: any;
  registerForm: FormGroup;
  forgotPwdform: FormGroup;
  submitted = false;
  UserProfilePic = "";
  isLoading = false;
  issue = true;
  ipAddress = '';
  emailSubmitted:boolean = false;
  GETSIGNIN = true;
  decodedToken: any;
  //jwtHelper = new JwtHelperService();
 
  rememberme: any = false;
  buttons:boolean = true;
  label:boolean = false;
  errormsg:boolean = false;
  loginForm:boolean = true;
  forgotPwdLink:boolean = false;
  showonmsgs:boolean = false;
  forsubmitted = false;
  data:any =[]

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    public rout: Router,
    private http:HttpClient,
    private modalService: NgbModal,
    private alertfy: AlertfyService
  ) {

   //keep me logged in 
   let userName = Cookie.get('userEmail')
   let remembermee = Cookie.get('rememberMe')

 if (userName && remembermee ) {
      this.issue = false;
      this.loginForm = false;
      this.rout.navigate(['/dashboard'])
  }
 else{
    localStorage.clear();
    this.rout.navigate(['/login'])
 }

    // var isLoadedBefore = localStorage.getItem("IsLoadedBefore");
    // if (isLoadedBefore == "true") {
    //   this.rout.navigate(['/login']);
    //   return;
    // } else {

    //   localStorage.setItem("IsLoadedBefore", 'true');
    // }

    // if(_cookieServc.get('remember')!==undefined){
    //   if(_cookieServc.get('remember')==="Yes"){
    //     this.registerForm.value.username = this._cookieServc.get("username");
    //     this.registerForm.value.password = this._cookieServc.get("password");
    //     this.remember = true;
    //   }
    // }

  
  }
  
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Enter") {
      //this.onSubmit();
    }
  }


  ngOnInit() {
  //  this.loginForm = true;
   // this.forgotPwdLink = false;
    this.registerForm  = this.fb.group({

      username: ['', [Validators.required],],
      password: ['', Validators.compose([Validators.required,])],
      rememberMe: new FormControl(false)
    }
    );
    this.forgotPwdform = this.fb.group({
      foremail: ['', [Validators.required, Validators.email]],
      
    // }, {
    //   validator: ConfirmedValidator('newpwd', 'cnfrmpwd')
    });
 //   localStorage.removeItem("token");

    //this.keepMeLogin();
    
    this.auth.isLoggedInCheck();
  }
  
 
  
   
  onSubmit() {
//     this.issue = false;
//  this.loginForm = false;
    console.log("entered main");
    //this.isLoading = true;
    
    this.submitted = true;
    if (this.registerForm.valid) {
      console.table(this.registerForm.value);
      
      if (this.registerForm.value.username == "murthy") {
        localStorage.setItem("userLogo", "murthy.jpg");
      } else if (this.registerForm.value.username == "pchavali") {
        localStorage.setItem("userLogo", "prasad.jpg");
          }
      //this.SpinnerService.show();
      this.isLoading = true;
 
      // this.auth.login(this.registerForm.value.username, this.registerForm.value.password);
      // this.isLoading = false;
      const data = {
        userid: this.username,
        password:this.password
         }
         this.auth.Login(data).subscribe((res:any)=>{
           if(res.status == 200){
             console.log(res,"response");
              setTimeout(() => {
                this.isLoading = false;
              },1200);
          //  this.isLoading = false;
             this.issue = false;
             this.loginForm = false;
             const user = res.response;
             if(user){ 
                 if(this.rememberme == true){
                  // localStorage.getItem('token')
                  localStorage.setItem('token', res.response[0].token)
                
                  localStorage.setItem('uId', res.response[0].u_uid)
                  localStorage.setItem('userinfo', JSON.stringify(res.response[0]));
                  localStorage.setItem('roleId', JSON.stringify(res.response[0].u_ur_id));
                  localStorage.setItem('profilePic',res.response[0].u_profile_img);
                  localStorage.setItem('ueraName',res.response[0].u_first_name +' '+res.response[0].u_last_name);
                
                  setTimeout(() => {
                    this.auth.getLocation();
                  }, 2000);
        
                  setTimeout(() => {
                    this.auth.epicFunction();
                  }, 5000); 
                   Cookie.set('userEmail', this.username,365);
                   Cookie.set('userPassword',this.password,365 );
                   Cookie.set('rememberMe',this.rememberme,365);
                   Cookie.set("cookie-key", "cookie-value", 365);
                   this.auth.isLoggedInCheck();
                 } else if (this.rememberme == false) {
                
                   Cookie.deleteAll();
               
                localStorage.setItem('token', res.response[0].token)
                console.log("checking", localStorage.getItem('token'));
                localStorage.setItem('uId', res.response[0].u_uid)
                localStorage.setItem('userinfo', JSON.stringify(res.response[0]));
                localStorage.setItem('roleId', JSON.stringify(res.response[0].u_ur_id));
                localStorage.setItem('profilePic',res.response[0].u_profile_img);
                localStorage.setItem('ueraName',res.response[0].u_first_name +' '+res.response[0].u_last_name);
              
                setTimeout(() => {
                  this.auth.getLocation();
                }, 2000);
      
                setTimeout(() => {
                  this.auth.epicFunction();
                }, 5000); 
                this.auth.isLoggedInCheck();
                 } else if (this.rememberme == true || this.username != Cookie.get('userEmail') || this.password != Cookie.get('userPassword')) {
                   Cookie.deleteAll();
                   Cookie.set('userEmail', this.username, 365);
                   Cookie.set('userPassword',this.password , 365);
                   Cookie.set('rememberMe',this.rememberme, 365);
                   Cookie.set("cookie-key", "cookie-value", 365);
                  
                 }
                // this.alert.success('Logged in succesfully');
                this.rout.navigate(['/dashboard'])
                }
           }
           else if(res.status == 401){
            this.isLoading = true;
                    this.alertfy.error("Invalid Credentials")
                  setTimeout(() => {
                    this.isLoading = false;
                  }, 1000);
                    
           }
         })
    //   this.isLoading = false;
    }
  }

  

  ResetPwd(content){
        // window.alert("opened ...fired")
        this.loginForm = false;
        this.forgotPwdLink = true;
       // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        this.errormsg = false;
        this.label = false;
        this.buttons = true;
        this.showonmsgs = false;
        this.forgotPwdform.reset();
  }

  closepanel(){
      this.forgotPwdform.reset();
      this.forsubmitted = false;
   // this.modalService.dismissAll();
      this.loginForm = true;
      this.forgotPwdLink = false;
  }

  SavePwd(email){
    this.forsubmitted = true;
    const data = {
      "u_email": email.foremail
    }
    console.log(email);
    if(this.forgotPwdform.valid){
    this.auth.GetEmail(data).subscribe((data:any) =>{
      if(data.status == 200){
          console.log(data);
          this.data = data.response
           console.log(this.data,"get from email");
       this.alertfy.success('Email sent successfully')
   //    this.rout.navigate(['/resetpassword',this.data[0].u_pcode_uniqueId]);
         
       console.log(data,"check ur email for further details");
        this.buttons= false;
        this.label =true ;
        this.showonmsgs = true;
        this.loginForm = true;
        this.forgotPwdLink = false;
        
      }else{
        //this.forgotPwdform.value.email =""
        this.buttons = false;
        this.errormsg = true;
        this.showonmsgs = true;

        setTimeout(()=>{ 
          // this.forgotPwdform.get('foremail').setValue('');
          // document.getElementById('foremail').value = '';
         // document.getElementById('foremailerror').style.display='none';
         this.errormsg = false;
         },3000);
        console.log("email not registered");
       
      }
      // this.errormsg = false;
      // this.buttons = true;
    })
   }
  }

  onlabelCncl(){
    this.loginForm = true;
    this.forgotPwdLink = false;
  }

  onchangeinput(e) {
    if ((this.username != '' && this.username != null) && (this.password != '' && this.password != null)) {
      this.GETSIGNIN = false;
    } else {
      this.GETSIGNIN = true;
    }
  }

  
  // onSubmit() {
  //   let val = this.registerForm.value;
  //   const data = {userid:val.username,password:val.password}
  //   console.log("failed");
  //   this.submitted = true;

  //   if (this.registerForm.valid) {
  //     console.table(this.registerForm.value);
  //     //  console.table.bind(this.getIPAddress);
  //     console.table(`this device address is ${this.ipAddress}`);
      
  //     if (this.registerForm.value.username == "murthy") {
  //       localStorage.setItem("userLogo", "murthy.jpg");
  //     } else if (this.registerForm.value.username == "pchavali") {
  //       localStorage.setItem("userLogo", "prasad.jpg");
  //     }
  //     //this.SpinnerService.show();
     
  //     this.isLoading = true;
  //       console.log("second  check");

  //     this.auth.login(data).subscribe((res:any) =>{
        
  //       console.log("third check");
  //       if(res.status == 200){
  //         console.log("fourth check");
  //         const user = res.response
  //         if(user){
           
  //         //  environment.loginData = res.response[0].u_first_name;
  //                  localStorage.setItem('token', res.response[0].token)
  //                  localStorage.setItem('uId', res.response[0].u_uid)
  //                  localStorage.setItem('userinfo', JSON.stringify(res.response[0]));
  //                    localStorage.setItem('roleId', JSON.stringify(res.response[0].u_ur_id));
  //                      localStorage.setItem('profilePic',res.response[0].u_profile_img);
  //                      localStorage.setItem('ueraName',res.response[0].u_first_name +' '+res.response[0].u_last_name);
  //                      this.auth.isLoggedInCheck();
  //                      localStorage.setItem('isLoggedIn', "true");  
  //                      this.decodedToken = this.jwtHelper.decodeToken(user.token);
  //                      if (this.rememberme == true) {
  //                       Cookie.set('userEmail', this.username);
  //                       Cookie.set('userPassword', this.password);
  //                       Cookie.set('rememberMe', this.rememberme);
  //                     } else if (this.rememberme == false) {
  //                       console.log(this.rememberme);
                        
  //                       Cookie.deleteAll();
  //                     } else if (this.rememberme == true || this.username != Cookie.get('userEmail') || this.password != Cookie.get('userPassword')) {
  //                       Cookie.deleteAll();
  //                       Cookie.set('userEmail', this.username);
  //                       Cookie.set('userPassword', this.password);
  //                       Cookie.set('rememberMe', this.rememberme);
  //                     }
  //                     this.rout.navigate(['/dashboard'])

          
  //         }
  //       }else{

  //       }
        

  //     })
        
      
  //     this.isLoading = false;
  
  //   }
  // }

}
