// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: "https://demoadmapi.netprophet.auto/api/",
  apiURL1:'http://devdmsapi.netprophet.auto/api/',
   npmWURL:'https://devnpmwapi.netprophet.auto/api/',
  // npmWURL:'http://10.10.10.84:3501/api/',
  userName: "",
  storeLogo: "https://devadmapi.netprophet.auto/api/storelogo/",
  dealersLogo: "https://devadmapi.netprophet.auto/api/dealergrouplogo/",
  brandslogo: "https://devadmapi.netprophet.auto/api/brandlogo/",
  userprofile: "https://devadmapi.netprophet.auto/api/img/",
  scprofileimage: "https://devapi.netprophet.auto/api/v1/resources/sc_profileimage/thumbs/",
  ACMDetailsURL:'http://demoapi.netprophet.auto/sq/api/',
  loginData: '',
  firebase: {
    apiKey: "AIzaSyADfQw_H5AggyrgYz1VVHe34bg4PfrLPZg",
    authDomain: "netprophet.firebaseapp.com",
    projectId: "netprophet",
    storageBucket: "netprophet.appspot.com",
    messagingSenderId: "1046565945702",
    appId: "1:1046565945702:web:2ec794531bdd97da6008a7",
    measurementId: "G-EM50MB3G4Z"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
