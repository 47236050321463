



 
   <div id="main"> 
    <loading *ngIf="isLoading">{{ isLoading }}</loading>

   </div>

<div *ngIf="issue">
<div class="login_bg">
    
    <div class="container pt-5">

        
        <div class="col-lg-4 offset-lg-2">
            <div class="row">
                <div class="col-12 my-4">
                   
                    <img src="assets/images/logo.png" class="img-fluid"></div>
                
               
            </div>

            <div *ngIf="loginForm">
               
                <form [formGroup]="registerForm" (submit)="onSubmit()">
                    <div class="login_form">
                        <input type="text" (input)="onchangeinput($event)" [(ngModel)]="username" class="login_input" formControlName="username" placeholder="UserName">
                        <span class="text-danger" *ngIf="(registerForm.get('username').touched || submitted) && registerForm.get('username').errors?.required">
                        UserName is required
                    </span>
                    </div>
                    <div class="login_form">
                        <input type="password" (input)="onchangeinput($event)" [(ngModel)]="password" class="login_input" formControlName="password" placeholder="Password">
                        <span class="text-danger" *ngIf="(registerForm.get('password').touched || submitted) && registerForm.get('password').errors?.required">
                        Password is required
                    </span>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-6 pl-2 " style="text-align: right;">
                            <label class="check">
                            <input type="checkbox" checked="checked"  formControlName="rememberMe" [(ngModel)]="rememberme">
                            <span class="checkmark"></span>Keep me logged in
                        </label>
                        </div>
                        <div class="col-lg-1 col-md-1 col-1 px-2" style="transform: scaleY(2.5); text-align: center; color: #aeaeae;">|</div>
                        <div class="col-lg-5 col-md-5 col-5 pr-lg-0 pl-lg-2 pl-md-2 forgot_txt"> <a style="color: dodgerblue;text-decoration: underline;cursor: pointer;" href="javascript:void(0);" (click)="ResetPwd(mymodal)">Forgot
                            password?</a></div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <input type="button" value="Sign in" class="Signin" (click)="onSubmit()">
                            <input type="submit" hidden ="true" (keydown.enter)="onSubmit()" class="SignIn">
                            
                        </div>
                    </div>
                   
                </form>
             
                <div>
                </div>
            
            </div>


            <div *ngIf="forgotPwdLink">
                <div [formGroup]="forgotPwdform">
                    <div class="form-row">
                        <div class="col-md-12">
                            <label><b>Your Email Please</b>
                        <span class="req" style="color:red;font: size 60%;">*</span>:</label>
                            <!-- <input type="text" class="form-control" formControlName="email" style="width:300px"> 
                   
                        <div style="font-size:100%;color: #dc3545;" *ngIf="!forgotPwdform.controls.email.valid && (forgotPwdform.controls.email.dirty||emailSubmitted)">
                           <b> Email is required </b>
                        </div>     -->

                            <div class="login_form" style="font-weight: bold;">
                                <input type="text" id="foremail" style="margin-bottom: 5px;" class="login_input" formControlName="foremail" placeholder="Enter Email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$">
                                <span class="text-danger" *ngIf="(forgotPwdform.get('foremail').touched || forsubmitted) && forgotPwdform.get('foremail').errors?.required">
                            Email is required
                        </span>
                                <small style="font-weight: bold;" class="text-danger" *ngIf="forsubmitted && forgotPwdform.controls.foremail.hasError('pattern')">Enter a valid email
                                address</small>
                            </div>

                        </div>
                        <br/>

                    </div>
                </div>
                <div>
                    <div class="btn-group col-md-12" style="padding-top: 1em;">
                        <button (click)="SavePwd(forgotPwdform.value)" class="Save_btn" type="submit">Send</button>
                        <button (click)="closepanel()" class="Cancel_btn" style="margin-left:60px;" type="button">Cancel</button>
                    </div>
                </div>
                <!-- <div *ngIf="label">
            <p class="successmail" >email sent successfully..pls check ur email for further updates.</p>
        </div> -->
                <div *ngIf="errormsg">
                    <p class="failmail">Email was not registered with us..!</p>
                </div>
            </div>

        </div>
        </div>