


import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private _authService: AuthService,
    private _router: Router) { }
    canActivate(): boolean {
      if (!this._authService.isAuthenticated()) {
        this._router.navigate(['/login']);
        return false;
      }
      return true;
    }

    
// //403 token expire

//  public isAuthenticated(): boolean {
//     const token = localStorage.getItem('token');
//     // Check whether the token is expired and return
//     // true or false
//     return !this.JwtHelperService.isTokenExpired(token);
//   }

}
