<div class="dashboard bg" [ngClass]="{'logStyle' : (isLoggIn == false && aggrmnt == false)}">
    <app-header *ngIf="isLoggIn && aggrmnt == false"></app-header>
    <div class="col" *ngIf="isLoggIn && aggrmnt == false">
        <div class="row">
            <div class="top_banner">
                <ul class="list-inline">
                    <li class="list-inline-item" routerLink="/dashboard" routerLinkActive="activetab"><a
                            routerLink="/dashboard">Overview</a></li>
                    <li class="list-inline-item" routerLink="/inventory" routerLinkActive="activetab"><a
                            routerLink="/inventory">DMS</a></li>
                    <li class="list-inline-item" routerLink="/cmsusers" routerLinkActive="activetab"><a
                            routerLink="/cmsusers">Users</a></li>
                </ul>
            </div>

        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-2 collapse show d-md-flex bg-dark px-0 nav" *ngIf="isLoggIn && aggrmnt == false">
                <ul class="nav flex-column flex-nowrap ">

                    <li class="nav-item" *ngFor="let item of menuModules; let $i=index">
                        <a class="nav-link collapsed" *ngIf="item.Mod_ParentId==0" [href]="'#submenu' +$i"
                            data-toggle="collapse" [data-target]="'#submenu'+$i" (click)="expand(i)"><span
                                class="pr-2"><img
                                    [src]="(item.mod_name == 'Dealer Setup') ? 'assets/images/sideNavBar/DealerSetup.png' : (item.mod_name == 'Documents')?'assets/images/sideNavBar/Documents.png':(item.mod_name == 'Reports')?'assets/images/sideNavBar/Reports.png':(item.mod_name == 'Administration')?'assets/images/sideNavBar/Administration.png':(item.mod_name == 'Messages')?'assets/images/sideNavBar/Messages.png':(item.mod_name == 'Support')?'assets/images/sideNavBar/Support.png':(item.mod_name == 'Content')?'assets/images/sideNavBar/Content.png':(item.mod_name == 'DMS')?'assets/images/sideNavBar/DMS.png': ''"></span>
                            <span class="d-none d-sm-inline">{{item.mod_name}}</span></a>
                        <div class="collapse submenu" [id]="'submenu'+$i" aria-expanded="false">
                            <ul class="flex-column nav" *ngFor="let subItem of menuModules" data-toggle="collapse"
                                data-target=".navbar-collapse">
                                <li class="nav-item" *ngIf="subItem.Mod_ParentId == item.p_mod_id">
                                    <a [class.active]="(activePath == subItem.mod_filename)" class="nav-link py-1"
                                        href="javascript:void(0);"
                                        [routerLink]="subItem.mod_filename"><span>{{subItem.mod_name}}</span></a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="nav-item"><a class="nav-link" href="javascript:void(0);" (click)="logOut()"><span
                                class="pr-2">
                                <img src="assets/images/logout-menu.png"></span> <span
                                class="d-none d-sm-inline">Logout</span></a></li>
                    <li>
                        <div class="ntfy-btn">
                            <label>Notifications</label>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 chat-bx">
                                <div class="header">
                                    <div style="font:400 14px/20px Roboto,'Helvetica Neue',sans-serif">Stores List</div>
                                    <div class="sub-title">{{monthyear}}</div>
                                </div>
                                <div class="content">
                                    <h5  style="font:330 14px/20px Roboto,'Helvetica Neue',sans-serif;cursor: pointer;" routerLink="/Migration">Stores for Migration: {{listcount}}</h5>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-9 py-3" [ngClass]="{'sfotpage': (aggrmnt == true)}">
                <div class="row">
                    <div class="col-12">
                        <div class="ml-3" [ngClass]="{'content_bg' : (isLoggIn == true && aggrmnt == false) }"
                            style="padding-top:20px !important">
                            <div class="row">

                                <router-outlet></router-outlet>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="footer_bg fixed-bottom"></div>


